import { StripePrice } from "@/prisma/schema/mysql";

export const planDescriptions = {
  freePlan: {
    name: "Free",
    slug: "free",
  },
  goldPlan: {
    name: "Gold",
    slug: "gold",
  },
  platinumPlan: {
    name: "Platinum",
    slug: "platinum",
  },
  diamondPlan: {
    name: "Diamond",
    slug: "diamond",
  },
  goldPlanYearly: {
    name: "1 year of Gold",
    slug: "goldYearly",
  },
  platinumPlanYearly: {
    name: "1 year of Platinum",
    slug: "platinumYearly",
  },
  diamondPlanYearly: {
    name: "1 year of Diamond",
    slug: "diamondYearly",
  },
};

export const PLANS = {
  freePlan: {
    productId: "",
    name: planDescriptions.freePlan.name,
    slug: planDescriptions.freePlan.slug,
    quota: 0,
    submissions: 0,
    prices: [] as StripePrice[],
    billing: "monthly" as "monthly",
    remoteMetadataName: "",
    comingSoon: false,
    quotaImportedGames: 100,
  },
  goldPlan: {
    productId: "",
    name: planDescriptions.goldPlan.name,
    slug: planDescriptions.goldPlan.slug,
    quota: 50,
    submissions: 50,
    prices: [] as StripePrice[],
    billing: "monthly" as "monthly",
    remoteMetadataName: "goldPlan" as "goldPlan",
    comingSoon: false,
    quotaImportedGames: 1000,
  },
  platinumPlan: {
    productId: "",
    name: planDescriptions.platinumPlan.name,
    slug: planDescriptions.platinumPlan.slug,
    quota: 75,
    submissions: 75,
    prices: [] as StripePrice[],
    billing: "monthly" as "monthly",
    remoteMetadataName: "platinumPlan" as "platinumPlan",
    comingSoon: true,
    quotaImportedGames: 3000,
  },
  diamondPlan: {
    productId: "",
    name: planDescriptions.diamondPlan.name,
    slug: planDescriptions.diamondPlan.slug,
    quota: 100,
    submissions: 100,
    prices: [] as StripePrice[],
    billing: "monthly" as "monthly",
    remoteMetadataName: "diamondPlan" as "diamondPlan",
    comingSoon: true,
    quotaImportedGames: 15000,
  },
  goldPlanYearly: {
    productId: "",
    name: planDescriptions.goldPlanYearly.name,
    slug: planDescriptions.goldPlanYearly.slug,
    quota: 50,
    submissions: 50,
    prices: [] as StripePrice[],
    billing: "yearly" as "yearly",
    remoteMetadataName: "goldPlan" as "goldPlan",
    comingSoon: true,
    quotaImportedGames: 1000,
  },
  platinumPlanYearly: {
    productId: "",
    name: planDescriptions.platinumPlanYearly.name,
    slug: planDescriptions.platinumPlanYearly.slug,
    quota: 75,
    submissions: 75,
    prices: [] as StripePrice[],
    billing: "yearly" as "yearly",
    remoteMetadataName: "platinumPlan" as "platinumPlan",
    comingSoon: true,
    quotaImportedGames: 3000,
  },
  diamondPlanYearly: {
    productId: "",
    name: planDescriptions.diamondPlanYearly.name,
    slug: planDescriptions.diamondPlanYearly.slug,
    quota: 100,
    submissions: 100,
    prices: [] as StripePrice[],
    billing: "yearly" as "yearly",
    remoteMetadataName: "diamondPlan" as "diamondPlan",
    comingSoon: true,
    quotaImportedGames: 15000,
  },
};

export const productDescriptions = {
  "100 credits": {
    name: "100 Credits",
    slug: "100 credits",
  },
};

export const PRODUCTS = {
  "100 credits": {
    priceId: "",
    name: productDescriptions["100 credits"].name,
    slug: productDescriptions["100 credits"].slug,
    quota: 100,
    submissions: 100,
    prices: [] as StripePrice[],
    remoteMetadataName: "100 xchess" as "100 xchess",
    comingSoon: true,
  },
};

export type ProductType = (typeof PRODUCTS)[keyof typeof PRODUCTS];
export type PlanType = (typeof PLANS)[keyof typeof PLANS];

const example = {
  id: "price_1PE64FEki3HAZ0EZB9UHK1wg",
  object: "price",
  active: true,
  billing_scheme: "per_unit",
  created: 1715157699,
  currency: "usd",
  custom_unit_amount: null,
  livemode: false,
  lookup_key: null,
  metadata: {},
  nickname: null,
  product: "prod_Q4EXDBL8rcy0lH",
  recurring: null,
  tax_behavior: "unspecified",
  tiers_mode: null,
  transform_quantity: null,
  type: "one_time",
  unit_amount: 999,
  unit_amount_decimal: "999",
};
